import React, { useRef, useState, useEffect } from "react";
import { RiImageAddLine } from "react-icons/ri";
import { MdVideoSettings } from "react-icons/md";
import Switch from "react-switch";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import { ISelectOption } from "../../../types/common";
import {
  sortedCountries as countries,
  europeanCountries,
} from "../../../utils/constant";
import Button from "../../../common/Button";
import { fileToBase64 } from "../../../helpers/functions";
import { IoIosClose, IoIosStar } from "react-icons/io";
import toast from "react-hot-toast";
import Api from "../../../api/api";
import axios from "axios";
import { useAuth } from "../../../context/auth";
import ImageEditor from "../../../components/ImageEditor";
import { FaPencilAlt } from "react-icons/fa";
import ImageUploader from "../../../components/ImageUploader";
import { useHistory } from "react-router-dom";
import ListingSuccessModal from "../../../components/ListingSuccessModal";
import Tooltip from "../../../components/Tooltip";
import Select from "react-select";
import { useCurrency } from "../../../hooks/useCurrency";
import UserItems from "./../../UserItemsPage";
import { get } from "http";
const NewItem = () => {
  const [isShow, setIsShow] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [video, setVideo] = useState<string>("");
  const history = useHistory();
  const { user } = useAuth();
  const [lastChosenCurrency, setLastCurrency] = useState<any>(null);

  const [categoriesOptions, setCategoriesOptions] = useState<any[]>([]);
  const [frequentCategories, setFrequentCategories] = useState<any[]>([]);
  const [frequentCountries, setFrequentCountries] = useState<any[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null,
  );
  const predefinedRanges = [
    { label: "2000–2025", value: "2000–2025" },
    { label: "1950–2000", value: "1950–2000" },
    { label: "1900–1950", value: "1900–1950" },
    { label: "Before 1900", value: "Before 1900" },
  ];
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [newItemId, setNewItemId] = useState<string | null>(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { userCurrency, convert, updateUserCurrency } = useCurrency(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    user,
    europeanCountries,
  );
  const [collectionsOptions, setCollectionsOptions] = useState<ISelectOption[]>(
    [],
  );

  const [data, setData] = useState({
    title: "",
    description: "",
    years: [""],
    category: "",
    country: "",
    collection: "",
    currency: lastChosenCurrency,
    price: 0,
    hidden: false,
    countries: [""],
    categories: [""],
    collections: [""],
  });
  async function getLastCurrency() {
    const res = await Api.getUserItem({ id: user?._id });
    if (res.status === 200) {
      const sortedByDate = res?.data?.data.sort(
        (a: any, b: any) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      );
      const lastItem = sortedByDate[0];

      setData({
        ...data,
        currency: lastItem.currency,
      });
    }
  }
  const cloudinaryRef = useRef("");
  const widgetRef = useRef<any>(null);
  useEffect(() => {
    getLastCurrency();
  }, [user]);
  useEffect(() => {
    const hasLoadingImage = images?.some((image: any) => image?.isLoading);

    setIsDisable(hasLoadingImage);
  }, [images]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    console.log(window.cloudinary);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error

    if (window.cloudinary) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      cloudinaryRef.current = window.cloudinary;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      widgetRef.current = cloudinaryRef.current.createUploadWidget(
        {
          // eslint-disable-next-line no-undef
          cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
          // eslint-disable-next-line no-undef
          uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        },
        function (err, result) {
          if (result && result.event === "success") {
            setVideo(result?.info);
          }
        },
      );
    }
  }, []);
  useEffect(() => {
    getAllCategories().then(() => {
      calculateFrequentSelections();
    });
    getAllCollections();
  }, [user?._id, categoriesOptions.length]);
  async function getAllCategories() {
    const res = await Api.getCategories();
    if (res.status === 200) {
      const cateOpt: any = [];
      for (let i = 0; i < res?.data?.data.length; i++) {
        cateOpt.push({
          value: res?.data?.data[i]._id,
          label: res?.data?.data[i].name,
        });
      }
      setCategoriesOptions(cateOpt);
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    if (name === "description") {
      const lineCount = value.split("\n").length;

      if (lineCount > 12) {
        toast.error("You can only add up to 12 lines.");
        return;
      }
    }

    setData(prev => ({ ...prev, [name]: value }));
  };

  async function getAllCollections() {
    const res = await Api.getAllCollection(user?._id);
    if (res?.status == 200) {
      const collOpt: any = [];
      for (let i = 0; i < res?.data?.data.length; i++) {
        collOpt.push({
          value: res?.data?.data[i]._id,
          label: res?.data?.data[i].name,
        });
      }
      setCollectionsOptions(collOpt);
    } else {
      return;
    }
  }
  async function calculateFrequentSelections() {
    const res = await Api.getUserItem({ id: user?._id });

    if (res.status === 200) {
      const categoriesCount: Record<string, number> = {};
      const countriesCount: Record<string, number> = {};
      const items = res?.data?.data;

      // Process the items for both categories and countries
      items.forEach((item: any) => {
        // Count categories
        item.categories.forEach((category: any) => {
          const categoryId = category._id;
          categoriesCount[categoryId] = (categoriesCount[categoryId] || 0) + 1;
        });

        // Count countries
        item.countries.forEach((ctry: string) => {
          countriesCount[ctry] = (countriesCount[ctry] || 0) + 1;
        });
      });

      if (categoriesOptions.length > 0) {
        const sortedCategories = Object.keys(categoriesCount)
          .sort((a, b) => categoriesCount[b] - categoriesCount[a])
          .slice(0, 4)
          .map(
            cat =>
              categoriesOptions.find(
                option => option.value == cat,
              ) as ISelectOption,
          )
          .filter(Boolean);

        setFrequentCategories(sortedCategories as ISelectOption[]);
      }
      // Process frequent countries
      const sortedCountries = Object.keys(countriesCount)
        .sort((a, b) => countriesCount[b] - countriesCount[a])
        .slice(0, 4)
        .map(
          ctry =>
            countries.find(option => option.value === ctry) as ISelectOption,
        )
        .filter(Boolean);

      setFrequentCountries(sortedCountries);
    }
  }

  const handleCollectionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCollection = e.target.value;
    if (selectedCollection && !data.collections.includes(selectedCollection)) {
      setData(prev => ({
        ...prev,
        collections: [...prev.collections, selectedCollection],
      }));
    }
  };
  const handleYearBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.trim() !== "") {
      handleYearInput(e.target.value.trim());
      e.target.value = "";
    }
  };
  const handleSaveEditedImage = async (editedImgBase64: string) => {
    if (selectedImageIndex !== null) {
      // Start loading spinner for the specific image being edited
      setImages(prevImages => {
        const updatedImages = [...prevImages];
        updatedImages[selectedImageIndex].isLoading = true; // Set loading for the selected image
        return updatedImages;
      });

      const imageToReplace = images[selectedImageIndex];
      setIsImageEditorOpen(false);
      try {
        const formData = new FormData();
        formData.append("file", editedImgBase64);
        formData.append(
          "upload_preset",
          process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET as string,
        );
        formData.append(
          "cloud_name",
          process.env.REACT_APP_CLOUDINARY_CLOUD_NAME as string,
        );

        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
          {
            method: "POST",
            body: formData,
          },
        );

        if (!response.ok) {
          throw new Error("Failed to upload edited image");
        }

        const dataTemp = await response.json();
        const newImage = {
          url: dataTemp.eager[0].secure_url,
          publicId: dataTemp.public_id,
        };

        if (imageToReplace.publicId) {
          await Api.deleteImage(imageToReplace.publicId);
        }

        setImages(prevImages => {
          const updatedImages = [...prevImages];
          updatedImages[selectedImageIndex] = {
            ...newImage,
            isLoading: false,
          };
          return updatedImages;
        });

        toast.success("Image edited and saved successfully");
      } catch (error) {
        console.error("Error uploading edited image to Cloudinary:", error);
        toast.error("Failed to save edited image. Please try again.");
        setImages(prevImages => {
          const updatedImages = [...prevImages];
          updatedImages[selectedImageIndex].isLoading = false;
          return updatedImages;
        });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const priceValue = data.price;
    if (isNaN(priceValue) || priceValue < 0.01) {
      toast.error("Please enter a valid price greater than or equal to 0.01");
      return;
    }
    setIsDisable(true);

    const customData = {
      name: data.title,
      description: data.description,
      country: data.country,
      photos: images,
      currency: data.currency,
      price: data.price,
      category: data.category,
      years: data.years,
      video: video ? video : "",
      hidden: !isShow,
      countries: data.countries.filter(ctry => ctry !== ""),
      categories: data.categories.filter(cat => cat !== ""),
    };

    try {
      const collectionToSend = data.collections.filter(coll => coll !== "");
      const res = await Api.addItem({
        collections: collectionToSend,
        ...customData,
      });
      if (res.status === 200) {
        toast.success("Item Added Successfully");
        setNewItemId(res?.data?.item?._id);
        setIsSuccessModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  const handleViewListing = () => {
    if (newItemId) {
      history.push(`/item/${newItemId}`);
    }
  };

  const handleCreateNewListing = () => {
    setData({
      title: "",
      description: "",
      years: [""],
      category: "",
      country: "",
      collection: "",
      currency: "USD",
      price: 0,
      hidden: false,
      countries: [""],
      categories: [""],
      collections: [""],
    });
    setImages([]);
    setVideo("");
    setIsSuccessModalOpen(false);
  };

  const handleCreateSimilarListing = () => {
    setData(prevData => ({
      ...prevData,

      price: 0,
    }));
    setImages([]);
    setVideo("");
    setIsSuccessModalOpen(false);
  };

  const handleRemoveCollection = (collection: string) => {
    setData(prev => ({
      ...prev,
      collections: prev.collections.filter(coll => coll !== collection),
    }));
  };

  const handleYearInput = (inputValue: string) => {
    const yearOrRange = inputValue.trim();
    const beforeRegex = /^before\s+(\d{1,4})$/i;
    const afterRegex = /^after\s+(\d{1,4})$/i;
    if (data.years.length > 2) {
      toast.error("Maximum 3 years allowed");
      return;
    }
    const addYearIfUnique = (newYear: string) => {
      if (data.years.includes(newYear)) {
        toast.error("Year must be unique");
      } else {
        setData(prev => ({
          ...prev,
          years: [...prev.years.filter(y => y !== ""), newYear],
        }));
      }
    };
    if (beforeRegex.test(yearOrRange)) {
      const yearMatch = yearOrRange.match(beforeRegex);
      if (yearMatch) {
        const year = parseInt(yearMatch[1], 10);
        if (year < -5000 || year > 2025) {
          toast.error("Year must be between 5000 B.C. and 2025");
          return;
        }
        addYearIfUnique(`Before ${year}`);
      }
    }
    // Check for "After" keyword
    else if (afterRegex.test(yearOrRange)) {
      const yearMatch = yearOrRange.match(afterRegex);
      if (yearMatch) {
        const year = parseInt(yearMatch[1], 10);
        if (year < -5000 || year > 2025) {
          toast.error("Year must be between 5000 B.C. and 2025");
          return;
        }
        addYearIfUnique(`After ${year}`);
      }
    }
    // Check for numeric year range
    else if (yearOrRange.includes("–")) {
      const [startYear, endYear] = yearOrRange.split("–").map(Number);

      if (isNaN(startYear) || isNaN(endYear) || startYear > endYear) {
        toast.error("Invalid year range");
        return;
      }
      addYearIfUnique(`${startYear}–${endYear}`);
    } else {
      const year = parseInt(yearOrRange, 10);
      if (isNaN(year) || year < -5000 || year > 2025) {
        toast.error("Year must be between 5000 B.C. and 2025");
        return;
      }
      addYearIfUnique(yearOrRange);
    }
  };
  const handlePredefinedRangeClick = (range: string) => {
    handleYearInput(range);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Next") {
      e.preventDefault();
      handleYearInput(e.currentTarget.value);
      e.currentTarget.value = "";
    }
  };
  const handleRemoveYear = (year: string) => {
    setData(prev => ({
      ...prev,
      years: prev.years.filter(y => y !== year && y !== ""),
    }));
  };

  const handleRemoveCategory = (category: string) => {
    setData(prev => ({
      ...prev,
      categories: prev.categories.filter(cat => cat !== category),
    }));
  };

  const handleRemoveCountry = (country: string) => {
    setData(prev => ({
      ...prev,
      countries: prev.countries.filter(ctry => ctry !== country),
    }));
  };

  return (
    <>
      <Header />
      <main>
        <div className="px-4 py-8 md:py-12 bg-primary2">
          <form
            onSubmit={handleSubmit}
            className="mx-auto max-w-[1260px] px-6 py-4 w-full rounded-xl bg-white"
          >
            <div className="flex items-center justify-between">
              <p className="textlg: text-xl font-bold">Add a new item</p>
              <div className="flex items-center gap-2">
                <Switch
                  checked={isShow}
                  onChange={setIsShow}
                />
                <p
                  className={
                    isShow
                      ? "text-green-500 font-semibold"
                      : "text-red-500 font-semibold"
                  }
                >
                  {isShow ? "Show" : "Hide"}
                </p>
              </div>
            </div>
            <br />
            <br />
            <div className="mb-5">
              <p className="uppercase font-semibold mb-1">PHOTOS & VIDEO</p>
              <div className="flex gap-6 max-md:flex-grid">
                <ImageUploader
                  images={images}
                  setImages={setImages}
                  video={video}
                  setVideo={setVideo}
                />
              </div>
            </div>
            <div className="max-w-[900px] mx-auto">
              <div className="mb-6">
                <p className="font-semibold">Item title</p>
                <input
                  name="title"
                  value={data.title}
                  onChange={handleInputChange}
                  maxLength={70}
                  className="w-full border-b border-[#000] py-2 text-[15px]"
                  required
                />
                <p className="text-end text-xs mt-1">
                  {data.title.length}/70 characters
                </p>
              </div>

              <div className="flex items-center gap-4 mb-6">
                <p className="font-semibold mb-1 min-w-[80px]">Category:</p>
                <Select
                  isMulti
                  name="categories"
                  controlShouldRenderValue={false}
                  options={[
                    {
                      label: "Frequently selected",
                      options: frequentCategories,
                    },
                    {
                      label: "All categories",
                      options: categoriesOptions,
                    },
                  ]}
                  /*categoriesOptions.filter(
                        option =>
                          !frequentCategories.some(
                            frequent => frequent.value === option.value,
                          ),
                      ),
                    },*/
                  value={categoriesOptions.filter(option =>
                    data.categories.includes(option.value),
                  )}
                  onChange={selectedOptions => {
                    const selectedValues = selectedOptions.map(
                      option => option.value,
                    );
                    if (selectedValues.length <= 3) {
                      setData(prev => ({
                        ...prev,
                        categories: selectedValues,
                      }));
                    } else {
                      toast.error("You can only select up to 3 categories.");
                    }
                  }}
                  placeholder="Select Categ..."
                  closeMenuOnSelect={false} // Keeps the menu open after each selection
                  className="w-[210px]"
                />
              </div>

              <div className="mb-4">
                {data.categories
                  .filter(category => category !== "")
                  .map((category, idx) => (
                    <div
                      key={idx}
                      className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium mr-2 mb-2  px-2.5 py-0.5 rounded"
                    >
                      {
                        categoriesOptions.find(cat => cat.value === category)
                          ?.label
                      }
                      <button
                        type="button"
                        onClick={() => handleRemoveCategory(category)}
                        className="ml-2  text-blue-800 hover:text-blue-900"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
              </div>

              <div className="flex items-center gap-4 mb-6">
                <p className="font-semibold mb-1 min-w-[80px]">Country:</p>
                <Select
                  isMulti
                  name="countries"
                  controlShouldRenderValue={false}
                  options={[
                    {
                      label: "Selected",
                      options: countries.filter(option =>
                        data.countries.includes(option.value),
                      ),
                    },
                    {
                      label: "Frequently selected",
                      options: frequentCountries,
                    },
                    {
                      label: "All countries",
                      options: countries.filter(
                        option =>
                          !frequentCountries?.some(
                            frequent => frequent.value === option.value,
                          ),
                      ),
                    },
                  ]}
                  value={countries.filter(option =>
                    data.countries.includes(option.value),
                  )}
                  onChange={selectedOptions => {
                    const selectedValues = selectedOptions.map(
                      option => option.value,
                    );
                    if (selectedValues.length <= 3) {
                      setData(prev => ({
                        ...prev,
                        countries: selectedValues,
                      }));
                    } else {
                      toast.error("You can only select up to 3 countries.");
                    }
                  }}
                  placeholder="Select Cou..."
                  closeMenuOnSelect={false} // Keeps the menu open after each selection
                  className="w-[210px]"
                  menuPlacement="auto"
                  menuShouldScrollIntoView={false} // Prevent automatic scrolling of the menu
                />
              </div>

              <div className="mb-4">
                {data.countries
                  .filter(country => country !== "")
                  .map((country, idx) => (
                    <div
                      key={idx}
                      className="inline-flex mb-2 items-center bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                    >
                      {countries.find(ctry => ctry.value === country)?.label}
                      <button
                        type="button"
                        onClick={() => handleRemoveCountry(country)}
                        className="ml-2 text-green-800 hover:text-green-900"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
              </div>

              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <p className="font-semibold mb-1 min-w-[80px]">Year(s):</p>
                  <input
                    type="text"
                    placeholder="Enter a year and press Enter"
                    onKeyDown={e => {
                      handleKeyDown(e);
                    }}
                    onBlur={handleYearBlur}
                    className="rounded-xl border border-[#000] p-2 text-[12px] w-[210px]"
                  />
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                  {data.years
                    .filter(year => year !== "")
                    .map((year, idx) => (
                      <div
                        key={idx}
                        className="inline-flex items-center bg-orange-100 text-orange-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                      >
                        {year}
                        <button
                          type="button"
                          onClick={() => handleRemoveYear(year)}
                          className="ml-2 text-orange-800 hover:text-orange-900"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                </div>
                <div className="flex items-center gap-2 mt-3">
                  <span className="font-medium">
                    Not sure of the year? Try selecting a period!
                  </span>
                  <span className="hidden sm:visible md:hidden font-medium">
                    (2000-2024)
                  </span>
                  {predefinedRanges.map((range, idx) => (
                    <button
                      key={idx}
                      type="button"
                      onClick={() => handlePredefinedRangeClick(range.value)}
                      className="text-primary hidden md:flex hover:underline focus:outline-none"
                    >
                      {range.label}
                    </button>
                  ))}
                </div>
              </div>

              <div className="mb-6">
                <p className="font-semibold mb-1">Item details</p>
                <textarea
                  name="description"
                  value={data.description}
                  onChange={handleInputChange}
                  maxLength={500}
                  style={{ whiteSpace: "pre-line" }}
                  className="w-full border border-[#000] min-w-full max-w-full min-h-[150px] rounded-xl p-2 text-[15px]"
                  required
                ></textarea>
                <p className="text-end text-xs mt-1">
                  {data.description?.length}/500 characters
                </p>
              </div>

              <div className="mb-6">
                <p className="font-semibold mb-1">Price</p>
                <div className="flex items-center gap-4">
                  <input
                    type="number"
                    name="price"
                    value={data.price}
                    onChange={handleInputChange}
                    placeholder="0.00"
                    min="0.01"
                    step="0.01"
                    className="w-[210px] border border-[#000] rounded-xl p-2 text-[15px]"
                    required
                  />
                  <select
                    name="currency"
                    value={data.currency}
                    onChange={e => {
                      handleInputChange(e);
                      e.target.blur();
                    }}
                    className="w-fit border border-[#000] rounded-xl p-2 text-[15px]"
                    required
                  >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="ILS">ILS</option>
                  </select>
                </div>
              </div>

              <div className="flex items-center gap-4 mb-6">
                <p className="font-semibold mb-1 min-w-[80px]">Collections:</p>
                <select
                  name="collection"
                  value=""
                  onChange={handleCollectionSelect}
                  className="rounded-xl border border-[#000] p-2 text-[15px] w-[210px]"
                >
                  <option value="">---Select---</option>
                  {collectionsOptions?.map((itm, idx) => (
                    <option
                      key={idx}
                      value={itm.value}
                    >
                      {itm.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                {data.collections
                  .filter(collection => collection !== "")
                  .map((collection, idx) => (
                    <div
                      key={idx}
                      className="inline-flex items-center bg-purple-100 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                    >
                      {
                        collectionsOptions.find(
                          coll => coll.value === collection,
                        )?.label
                      }
                      <button
                        type="button"
                        onClick={() => handleRemoveCollection(collection)}
                        className="ml-2 text-purple-800 hover:text-purple-900"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
              </div>

              <Button
                type="submit"
                disabled={isDisable}
                className="!w-fit ml-auto !block !px-6"
              >
                Add Item
              </Button>
              <br />
            </div>
          </form>
        </div>
      </main>
      {selectedImage && (
        <ImageEditor
          isOpen={isImageEditorOpen}
          imageSrc={selectedImage}
          onClose={() => setIsImageEditorOpen(false)}
          onSave={handleSaveEditedImage}
        />
      )}
      <ListingSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false), history.push(`/seller/${user?._id}`);
        }}
        onViewListing={handleViewListing}
        onCreateNewListing={handleCreateNewListing}
        onCreateSimilarListing={handleCreateSimilarListing}
        listingImage={images[0]?.url || ""}
        listingTitle={data.title}
      />
      <Footer />
    </>
  );
};

export default NewItem;
